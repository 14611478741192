@import url("https://fonts.googleapis.com/css2?family=Alata&family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900&display=swap");

body {
  width: 100% !important;
  font-family: "Alata", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background: #18181b !important;
  overflow: hidden;
}

.main {
  position: relative;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 10vmin;
}

.clock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Analog Clock */
.clock {
  position: relative;
  display: flex;
  align-items: center;
  width: 580px;
  height: 580px;
  justify-content: center;
  justify-self: center;
  transition: 0.4s;
  border: solid 9px transparent;
  border-radius: 50%;
  background-image: linear-gradient(#001729, #18181b), radial-gradient(circle at top left,#E91E63, #B71C1C);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}


.line_wrapper {
  margin-top: -15px;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

.white_logo {
  animation: float 6s ease-in-out infinite;
}

.line {
  width: 9px;
  height: 18px;
  background-color: #E91E63;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 0 275px;
  border-radius: 10px;
}

.line:nth-child(1) {
  transform: translateY(-275px);
}
.line:nth-child(2) {
  transform: translateY(-275px) rotateZ(calc(360deg / 12));
}
.line:nth-child(3) {
  transform: translateY(-275px) rotateZ(calc(2 * 360deg / 12));
}
.line:nth-child(4) {
  transform: translateY(-275px) rotateZ(calc(3 * 360deg / 12));
}
.line:nth-child(5) {
  transform: translateY(-275px) rotateZ(calc(4 * 360deg / 12));
}
.line:nth-child(6) {
  transform: translateY(-275px) rotateZ(calc(5 * 360deg / 12));
}
.line:nth-child(7) {
  transform: translateY(-275px) rotateZ(calc(6 * 360deg / 12));
}
.line:nth-child(8) {
  transform: translateY(-275px) rotateZ(calc(7 * 360deg / 12));
}
.line:nth-child(9) {
  transform: translateY(-275px) rotateZ(calc(8 * 360deg / 12));
}
.line:nth-child(10) {
  transform: translateY(-275px) rotateZ(calc(9 * 360deg / 12));
}
.line:nth-child(11) {
  transform: translateY(-275px) rotateZ(calc(10 * 360deg / 12));
}
.line:nth-child(12) {
  transform: translateY(-275px) rotateZ(calc(11 * 360deg / 12));
}

.line::after {
  font-size: 60px;
  font-weight: 700;
  display: inline-block;
  color: #fbfcff;
  margin: 20px 5px 20px -15px;
  text-shadow: 0 13.36px 8.896px #18181b, 0 -2px 1px #000;
}

.line:nth-child(1)::after {
  content: "12";
  transform: translateX(-10px);
}

.line:nth-child(2)::after {
  content: "1";
  transform: rotateZ(calc(-360deg / 12));
}

.line:nth-child(3)::after {
  content: "2";
  transform: rotateZ(calc(-2 * 360deg / 12));
}

.line:nth-child(4)::after {
  content: "3";
  transform: rotateZ(calc(-3 * 360deg / 12));
}

.line:nth-child(5)::after {
  content: "4";
  transform: rotateZ(calc(-4 * 360deg / 12));
}

.line:nth-child(6)::after {
  content: "5";
  transform: rotateZ(calc(-5 * 360deg / 12));
}

.line:nth-child(7)::after {
  content: "6";
  transform: rotateZ(calc(-6 * 360deg / 12));
}

.line:nth-child(8)::after {
  content: "7";
  transform: rotateZ(calc(-7 * 360deg / 12));
}

.line:nth-child(9)::after {
  content: "8";
  transform: rotateZ(calc(-8 * 360deg / 12));
}

.line:nth-child(10)::after {
  content: "9";
  transform: rotateZ(calc(-9 * 360deg / 12));
}

.line:nth-child(11)::after {
  content: "10";
  transform: rotateZ(calc(-10 * 360deg / 12));
}

.line:nth-child(12)::after {
  content: "11";
  transform: rotateZ(calc(-11 * 360deg / 12));
}

.clock .hours {
  border: 6px solid hsl(240, 12%, 35%);
  position: absolute;
  height: 95%;
  border-radius: 4px;
  left: 97px;
  top: 102px;
  animation-duration: 43200s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.clock .minutes {
  border-radius: 4px;
  border: 2px solid hsl(240, 12%, 35%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  left: 99px;
  top: 200px;
  height: 55%;
  animation-duration: 3600s;
}

.clock .sec {
  border-radius: 4px;
  border: 1px solid hsl(0, 69%, 41%);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  left: 100px;
  top: 100px;
  height: 90%;
  animation-duration: 60s;
}

.clock_rounder {
  width: 0.9rem;
  height: 0.9rem;
  background-color: #b12623;
  border-radius: 50%;
  border: 1px solid #fbfcfc;
  z-index: 10;
}

.animate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.hand {
  transform-origin: top center;
}

.clock_hour,
.clock_minutes,
.clock_seconds {
  position: absolute;
  display: flex;
  justify-content: center;
}

.clock_hour {
  width: 105px;
  height: 250px;
  animation-duration: 43200s;
}

.clock_hour::before {
  content: "";
  position: absolute;
  background-color: #fbfcfc;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  width: 0.7rem;
  height: 9rem;
  border-radius: 0.75rem;
  z-index: 1;
}

.clock_minutes {
  width: 136px;
  height: 350px;
  animation-duration: 3600s;
}

.clock_minutes::before {
  content: "";
  position: absolute;
  background-color: #fbfcfc;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  width: 0.7rem;
  height: 12rem;
  border-radius: 0.75rem;
  z-index: 1;
}

.clock_seconds {
  width: 230px;
  height: 400px;
  animation-duration: 60s;
}

.clock_seconds::before {
  content: "";
  position: absolute;
  background-color: #e53935;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  width: 0.25rem;
  height: 12rem;
  border-radius: 0.75rem;
  z-index: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
